import React, { useState, useEffect, useContext, createRef } from 'react';
import { Modal, Container, Button, Form, Message, Grid, Table, Segment, Icon, Input, Accordion, AccordionTitle, AccordionContent, Label, TableHeaderCell, Dropdown, List, Divider, Select, Image, Checkbox } from 'semantic-ui-react'
import { useToasts } from 'react-toast-notifications';
import { ConnectWS } from '../../../ConnectWS'
import AuthContext from '../../../context/authContext';
import useWindowDimensions from '../../../hooks/useWindowDimensions';
import useAuth from '../../../hooks/useAuth';
import { blockEditScore, changeFormatDateTimeBS, getColorWinner, getFormIcon, getInfoTab, getSkut, getStosunekPTK, getlinkplayerprofile, mobileWSmall } from '../../../myfunctions';
import ShowImgAvatar from '../../../tools/ShowImgAvatar';
import GetScoreDetails from './GetScoreDetails';
import ShowScoreDetails from './ShowScoreDetails';
import { useTranslation } from 'react-i18next'
import EditLeagueMatchInfo from '../../../tools/EditLeagueMatchInfo';
import EditLeagueMatchDetailsEx from '../../../tools/EditLeagueMatchDetailsEx';
import useCheckUserIsDemo from '../../../hooks/useCheckUserIsDemo';
import { getMembersStatsAll, getTopXMembers } from '../../../leagueFuntions';
import InputCalendarAndTime from '../../../tools/InputCalendarAndTime';
import GooglePhotosViewer from '../../../tools/GooglePhotosViewer';

function BracketLeague(propsRoot) {
    const { addToast } = useToasts();
    const { t } = useTranslation();
    const authContext = useContext(AuthContext);
    const { width, height } = useWindowDimensions();// eslint-disable-line no-unused-vars
    const [auth, setAuth] = useAuth();// eslint-disable-line no-unused-vars
    const [loading, setloading] = useState(false)
    const [activeIndex, setactiveIndex] = useState(0)
    const [openModalScore, setopenModalScore] = useState(null)// eslint-disable-line no-unused-vars
    const [openModalDate, setopenModalDate] = useState(null)// eslint-disable-line no-unused-vars
    const [messageStatus, setmessageStatus] = useState({ state: 0, text: "" })
    const isDemo = useCheckUserIsDemo()
    const [listUsersParams, setlistUsersParams] = useState({ round_nr: 1, league_revenge_round: 0 })
    const [openModalmatch_info, setopenModalmatch_info] = useState({ open: false, row: null })
    const [openModalmatch_details_ex, setopenModalmatch_details_ex] = useState({ open: false, row: null, onlyView: 1, st: null })


    const [teamsAdvance, setteamsAdvance] = useState(0)// eslint-disable-line no-unused-vars
    const [teamsRelegated, setteamsRelegated] = useState(0)// eslint-disable-line no-unused-vars
    const [checkboxMD, setcheckboxMD] = useState(true)// eslint-disable-line no-unused-vars

    const [roundOptions, setroundOptions] = useState([])// eslint-disable-line no-unused-vars
    const [membersOptions, setmembersOptions] = useState([])// eslint-disable-line no-unused-vars
    const [currRound, setCurrRound] = useState(0)
    const [currMember, setcurrMember] = useState(0)
    const [showDraw, setshowDraw] = useState(false)
    const [GooglePhotosViewerStatus, setGooglePhotosViewerStatus] = useState({ url: null, openView: false })

    const [userList, setuserList] = useState([])

    const [top10members, settop10members] = useState([])
    const [membersStatsAll, setmembersStatsAll] = useState({ column: null, data: [], direction: null, filterData: null })


    function clickSortTable(column, index) {

        let directiontmp = membersStatsAll.direction === 'ascending' ? 'descending' : 'ascending'
        if (directiontmp === "ascending") {
            setmembersStatsAll({ column: column, data: membersStatsAll.data.sort(function (a, b) { return a.stats[index].value - b.stats[index].value }), direction: directiontmp, filterData: membersStatsAll.filterData ? membersStatsAll.filterData.sort(function (a, b) { return a.stats[index].value - b.stats[index].value }) : membersStatsAll.data.sort(function (a, b) { return a.stats[index].value - b.stats[index].value }) })
        } else if (directiontmp === "descending") {
            setmembersStatsAll({ column: column, data: membersStatsAll.data.sort(function (a, b) { return b.stats[index].value - a.stats[index].value }), direction: directiontmp, filterData: membersStatsAll.filterData ? membersStatsAll.filterData.sort(function (a, b) { return b.stats[index].value - a.stats[index].value }) : membersStatsAll.data.sort(function (a, b) { return b.stats[index].value - a.stats[index].value }) })
        }

    }


    function searchTableDataStats(value) {
        try {


            if (value && value.length >= 3) {

                if (membersStatsAll.data) {
                    let tt = []
                    membersStatsAll.data.forEach((row) => {

                        if (row.member_name.toLocaleUpperCase().includes(value.toLocaleUpperCase()) || row.team_name.toLocaleUpperCase().includes(value.toLocaleUpperCase())) {

                            tt.push(row)
                        }
                    })
                    setmembersStatsAll({ ...membersStatsAll, filterData: tt })

                } else {
                    setmembersStatsAll({ ...membersStatsAll, filterData: membersStatsAll.data })

                }

            } else {
                setmembersStatsAll({ ...membersStatsAll, filterData: membersStatsAll.data })

            }

        } catch (e) {
            console.log(e)
        }

    }


    const [league_revenge_round, setleague_revenge_round] = useState(0)

    const [form, setForm] = useState({
        subtournament_id: null,
        league_winners_count: 0,
        league_losers_count: 0
    });

    function hiddenDraw() {
        if (propsRoot.systemId === 1 || propsRoot.systemId === 4 || propsRoot.systemId === 7 || propsRoot.systemId === 9 || propsRoot.systemId === 12) {
            setshowDraw(true)
        } else {
            setshowDraw(false)
        }
    }

    const [isDetailsScore, setisDetailsScore] = useState(false)

    function checkIsDetailsScore() {

        let ret = false
        if (propsRoot.remoteJson && propsRoot.remoteJson.final_table && propsRoot.remoteJson.final_table.length > 0) {
            propsRoot.remoteJson.final_table.forEach((line3) => {
                if (line3.total_details_points_winners) {
                    ret = true
                    return true
                }
            })
        }

        return ret;
    }

    function getListUsers(subtournament_id, round_nr, league_revenge_round) {
        ConnectWS("/generator/getUnassignedSubtournamentMembers", authContext.token, { subtournament_id: subtournament_id, round_nr: round_nr, league_revenge_round: league_revenge_round }, function (response, logout) {

            if (logout === 1) {
                addToast('Zaraz nastapi wylogowanie: ' + response.error, { appearance: 'error' });
                setTimeout(() => {
                    setAuth(false);
                }, 4000);
            } else if (response && response.status && Number(response.status) === 200) {
                //setDataOpen(dataJson)
                let dictemat = []
                dictemat.length = 0
                response.data.forEach((data, i) => {
                    if (data.member_id > 0) {
                        dictemat.push({ key: i, text: data.member_name, value: data.member_id })
                    }

                })
                setuserList(dictemat);
            } else {

            }



        });
    }

    useEffect(() => {// eslint-disable-line react-hooks/exhaustive-deps

        hiddenDraw()
        setForm({
            ...form,
            subtournament_id: propsRoot.subtournament_id,
        })

        if (propsRoot && propsRoot.remoteJson && propsRoot.remoteJson.league_info && propsRoot.remoteJson.league_info.winners_count) {
            setteamsAdvance(propsRoot.remoteJson.league_info.winners_count)
            setteamsRelegated(propsRoot.remoteJson.league_info.losers_count)
            setForm({
                subtournament_id: propsRoot.subtournament_id,
                league_winners_count: propsRoot.remoteJson.league_info.winners_count,
                league_losers_count: propsRoot.remoteJson.league_info.losers_count
            })

        }

        if (propsRoot && propsRoot.subtournamentId && propsRoot.struct_confirmed === 0) {
            getListUsers(propsRoot.subtournamentId, listUsersParams.round_nr, listUsersParams.league_revenge_round)
        }


        if (propsRoot && propsRoot.remoteJson && propsRoot.remoteJson.tournament_json) {

            settop10members(getTopXMembers(propsRoot.remoteJson.tournament_json, propsRoot.remoteJson.members, 1000))
            let ttt = getMembersStatsAll(propsRoot.remoteJson.tournament_json, propsRoot.remoteJson.members, 1000)

            setmembersStatsAll({ ...membersStatsAll, data: ttt, filterData: membersStatsAll.filterData && membersStatsAll.filterData.length > 0 ? membersStatsAll.filterData : ttt })
            let tmparr2 = []
            setisDetailsScore(checkIsDetailsScore())

            let league_revenge_roundtmp = 0
            tmparr2.push({ key: 0, value: 0, text: t("WidokTurnieju.wszystkie_kolejki") })
            propsRoot.remoteJson.tournament_json.forEach((row) => {
                if (row.league_revenge_round > 0 && league_revenge_roundtmp === 0) {
                    league_revenge_roundtmp = 1
                }
                tmparr2.push({ key: row.round_nr, value: row.round_nr, text: row.league_revenge_round > 0 ? t("WidokTurnieju.kolejka") + ' ' + row.round_nr + " (" + row.league_revenge_round + " " + t("WidokTurnieju.rewanzowa") + ")" : t("WidokTurnieju.kolejka") + ' ' + row.round_nr })
            })
            setleague_revenge_round(league_revenge_roundtmp)
            setroundOptions(tmparr2)


            let tmparr3 = []
            tmparr3.push({ key: 0, value: 0, text: t("WidokTurnieju.wszycy_uczestnicy") })
            propsRoot.remoteJson.members.forEach((row) => {
                tmparr3.push({ key: row.id, value: row.id, text: row.name.toUpperCase(), image: { avatar: true, src: row.img_file ? row.img_file : process.env.REACT_APP_PUBLIC_URL + '/img/nouser.png' } })
            })
            setmembersOptions(tmparr3)
        }




        return () => { }; // use effect cleanup to set flag false, if unmounted
    }, [propsRoot.struct_confirmed, propsRoot.remoteJson, propsRoot.subtournaments, propsRoot.subtournamentId])// eslint-disable-line react-hooks/exhaustive-deps


    useEffect(() => {// eslint-disable-line react-hooks/exhaustive-deps
        if (propsRoot && propsRoot.subtournamentId && propsRoot.struct_confirmed === 0) {
            getListUsers(propsRoot.subtournamentId, listUsersParams.round_nr, listUsersParams.league_revenge_round)
        }
        return () => { }; // use effect cleanup to set flag false, if unmounted
    }, [activeIndex])// eslint-disable-line react-hooks/exhaustive-deps




    function delScore() {
        setmessageStatus({ state: 0, text: "" })
        ConnectWS("/generator/setMatchScoreLeague", authContext.token, { ...openModalScore, subtournament_id: propsRoot.subtournament_id, removeScore: true }, function (response, logout) {

            if (logout === 1) {
                addToast('Zaraz nastapi wylogowanie: ' + response.error, { appearance: 'error' });
                setTimeout(() => {
                    setAuth(false);
                }, 4000);
            } else if (response && response.status && Number(response.status) === 200) {
                setopenModalScore(null)
                addToast(response.data.message, { appearance: 'success' });
                authContext.changeRefreshView(authContext.refreshView + 1)
            } else {
                setmessageStatus({ state: 1, text: response })
            }



        });
    }

    function delDate() {
        setloading(true)
        ConnectWS("/generator/setMatchDateLeague", authContext.token, { ...openModalDate, subtournament_id: propsRoot.subtournament_id, match_date: null }, function (response, logout) {

            if (logout === 1) {
                addToast('Zaraz nastapi wylogowanie: ' + response.error, { appearance: 'error' });
                setTimeout(() => {
                    setAuth(false);
                }, 4000);
            } else if (response && response.status && Number(response.status) === 200) {
                setopenModalDate(null)
                addToast(response.data.message, { appearance: 'success' });
                authContext.changeRefreshView(authContext.refreshView + 1)
                setmessageStatus({ state: 0, text: "" })
            } else {
                setmessageStatus({ state: 1, text: response })
            }
            setloading(false)


        });
    }

    const saveDatePre = async e => {
        e.preventDefault();
        setloading(true)
        ConnectWS("/generator/setMatchDateLeague", authContext.token, { ...openModalDate, subtournament_id: propsRoot.subtournament_id, match_date: openModalDate.match_date === null || openModalDate.match_date === "" ? changeFormatDateTimeBS(new Date()) : changeFormatDateTimeBS(openModalDate.match_date) }, function (response, logout) {

            if (logout === 1) {
                addToast('Zaraz nastapi wylogowanie: ' + response.error, { appearance: 'error' });
                setTimeout(() => {
                    setAuth(false);
                }, 4000);
            } else if (response && response.status && Number(response.status) === 200) {
                setopenModalDate(null)
                addToast(response.data.message, { appearance: 'success' });
                authContext.changeRefreshView(authContext.refreshView + 1)
                setmessageStatus({ state: 0, text: "" })
            } else {
                setmessageStatus({ state: 1, text: response })
            }
            setloading(false)


        });
    }



    const saveScorePre = async e => {
        e.preventDefault();
        saveScore()
    }

    const setLeagueParams = async e => {
        e.preventDefault();
        ConnectWS("/generator/setLeagueParams", authContext.token, { ...form }, function (response, logout) {
            if (logout === 1) {
                addToast('Zaraz nastapi wylogowanie: ' + response.error, { appearance: 'error' });
                setTimeout(() => {
                    setAuth(false);
                }, 4000);

            } else if (response && response.status && Number(response.status) === 200) {
                addToast(response.data.message, { appearance: 'success' });
                authContext.changeRefreshView(authContext.refreshView + 1)
            } else {
                if (logout === 101) {
                    addToast(response, { appearance: 'info' });
                } else if (logout === 102) {
                    addToast(response, { appearance: 'warning' });
                } else {
                    addToast(response, { appearance: 'error' });
                }
            }

        });

    }



    function saveScore() {



        let nmin = 0
        let nmax = 200
        let removeScore = false;


        if (openModalScore.member1_score === null && openModalScore.member2_score === null) {
            // setmessageStatus({ state: 1, text: "Wpisz wynik meczu " })
            // return
            removeScore = true
        }

        if (openModalScore.member1_score === null) {
            setmessageStatus({ state: 1, text: "Wpisz wynik dla czerwonego" })
            return
        } else if (openModalScore.member1_score < nmin || openModalScore.member1_score > nmax) {
            setmessageStatus({ state: 1, text: "Wpisana wartość wyniku musi być z przedziału od " + nmin + " do " + nmax })
            return
        }
        if (openModalScore.member2_score === null) {
            setmessageStatus({ state: 1, text: "Wpisz wynik dla niebieskiego" })
            return
        } else if (openModalScore.member2_score < nmin || openModalScore.member2_score > nmax) {
            setmessageStatus({ state: 1, text: "Wpisana wartość wyniku musi być z przedziału od " + nmin + " do " + nmax })
            return
        }

        if (openModalScore.member1_score === "" || openModalScore.member2_score === "") {
            removeScore = true
        }



        let jsonObj = { ...openModalScore, member1_score: openModalScore.member1_score, member2_score: openModalScore.member2_score, removeScore: removeScore, subtournament_id: propsRoot.subtournament_id }
        setloading(true)
        ConnectWS("/generator/setMatchScoreLeague", authContext.token, jsonObj, function (response, logout) {

            if (logout === 1) {
                addToast('Zaraz nastapi wylogowanie: ' + response.error, { appearance: 'error' });
                setTimeout(() => {
                    setAuth(false);
                }, 4000);
            } else if (response && response.status && Number(response.status) === 200) {
                setopenModalScore(null)
                addToast(response.data.message, { appearance: 'success' });
                authContext.changeRefreshView(authContext.refreshView + 1)
                setmessageStatus({ state: 0, text: "" })
            } else {
                setmessageStatus({ state: 1, text: response })
            }
            setloading(false)


        });

    }




    function showScoreDetails2(md, match_member1_id, match_member2_id, fs = 16) {
        try {
            let tmparr = JSON.parse(md).score
            let tmparr2 = []
            tmparr.forEach((row1) =>
                tmparr2.push(" " + row1[match_member1_id] + ":" + row1[match_member2_id])
            )
            if (tmparr2.length > 0) {
                return <span style={{ fontSize: fs }}>{tmparr2.toString()}</span>
            } else {
                return ""
            }

        } catch (e) {
            return ""
        }
    }

    function showScoreDTab(md, match_member_id) {
        try {
            if (JSON.parse(md).score && JSON.parse(md).score.length > 0) {
                return JSON.parse(md).score.map((row1, i) => <Table.Cell width={1} key={i} style={{ fontSize: 12 }} textAlign='center' singleLine>
                    {row1[match_member_id]}
                </Table.Cell>)
            } else {
                return ""
            }

        } catch (e) {
            return ""
        }
    }



    let contextRef = createRef()

    function funCurrRound(value) {
        if (value > 0 || currMember > 0) {
            document.getElementById('showtl').click();
        } else {
            document.getElementById('hidetl').click();
        }

        setCurrRound(value)
    }

    function funcurrMember(value) {
        if (value > 0 || currRound > 0) {
            document.getElementById('showtl').click();
        } else {
            document.getElementById('hidetl').click();
        }
        setcurrMember(value)
    }

    function colorFontsScore(member1_id, line, co) {
        try {
            if (member1_id === line.member1_id && line.member1_score > line.member2_score && line.member1_score !== null && line.member2_score !== null) {
                return "#31a317"
            } else if (member1_id === line.member2_id && line.member1_score < line.member2_score && line.member1_score !== null && line.member2_score !== null) {
                return "#31a317"
            } else if (line.member1_score === line.member2_score && line.member1_score !== null && line.member2_score !== null) {
                return "#dba621"
            } else if (line.member1_score !== line.member2_score && line.member1_score !== null && line.member2_score !== null) {
                return "#cf1313"
            }
            return "#000"
        } catch (e) {
            return "#000"
        }
    }

    function showTableH2hScore(member1_id, member2_id, league_revenge_round) {
        try {
            let score = ""
            for (const line2 of propsRoot.remoteJson.tournament_json) {

                if ((line2.league_revenge_round > 0 && league_revenge_round === 1) || line2.league_revenge_round === league_revenge_round) {


                    for (const line of line2.matches) {
                        if (line.member1_id === member1_id && line.member2_id === member2_id && line.member1_score !== null && line.member2_score !== null) {
                            score = <><div style={{ fontWeight: "bold", color: colorFontsScore(member1_id, line, 1) }}>{line.member1_score}:{line.member2_score}</div>
                                {line.match_details ? <div> {showScoreDetails2(line.match_details, line.match_member1_id, line.match_member2_id, 11)} </div> : ""}
                            </>

                            break;
                        } else if (line.member1_id === member2_id && line.member2_id === member1_id && line.member1_score !== null && line.member2_score !== null) {
                            score = <><div style={{ fontWeight: "bold", color: colorFontsScore(member1_id, line, 2) }}>{line.member2_score}:{line.member1_score} </div>
                                {line.match_details ? <div> {showScoreDetails2(line.match_details, line.match_member2_id, line.match_member1_id, 11)} </div> : ""}
                            </>

                            break;
                        }

                    }
                }
            }
            return score

        } catch (e) {
            return ""
        }
    }



    function getmatch_info(match_info, line3) {
        try {
            const rows = [];
            if (propsRoot.onlyView === 1) {

            } else {
                rows.push(<Icon className='cursorPointer hiddenPrint' title="edytuj dane" name="edit" color='orange' onClick={() => setopenModalmatch_info({ open: true, row: line3 })} />)
            }

            for (const [key] of Object.entries(match_info).sort((a, b) => a[1].sort - b[1].sort)) {
                if (match_info[key].type === "inputurl" && match_info[key].name === "match_report") {
                    if (match_info[key].value) {
                        if (width < 1000) {
                            rows.push(<span style={{ marginRight: 5 }}>
                                <a className={"tllink"} href={match_info[key].value} target="_blank" rel="noopener noreferrer"><Icon color='green' name={match_info[key].icon} />{t("stats." + match_info[key].name)}</a>
                            </span>)
                        } else {
                            rows.push(<span style={{ marginRight: 5 }} className='cursorPointer' onClick={() => authContext.changeModalInfo({ nameModal: "MatchReport", name: t("stats." + match_info[key].name), value: match_info[key].value })}>
                                <Icon color='green' name={match_info[key].icon} />{t("stats." + match_info[key].name)}
                            </span>)
                        }

                    } else if (propsRoot.onlyView === 0) {
                        rows.push(<span style={{ marginRight: 5 }}><Icon color='grey' name={match_info[key].icon} />{t("stats." + match_info[key].name)}</span>)
                    }
                } else if (match_info[key].type === "inputurl") {
                    if (match_info[key].value) {
                        rows.push(<span style={{ marginRight: 5 }}>
                            <a className={"tllink"} href={match_info[key].value} target="_blank" rel="noopener noreferrer"><Icon color='green' name={match_info[key].icon} />{t("stats." + match_info[key].name)}</a>
                        </span>)
                    } else if (propsRoot.onlyView === 0) {
                        rows.push(<span style={{ marginRight: 5 }}><Icon color='grey' name={match_info[key].icon} />{t("stats." + match_info[key].name)}</span>)
                    }
                } else if (match_info[key].type === "inputvideourl") {
                    if (match_info[key].value) {
                        rows.push(<span style={{ marginRight: 5 }} className='cursorPointer' onClick={() => authContext.changeModalInfo({ nameModal: "VideoModal", name: t("stats." + match_info[key].name), value: match_info[key].value })}>
                            <Icon color='green' name={match_info[key].icon} />{t("stats." + match_info[key].name)}
                        </span>)
                    }
                    else if (propsRoot.onlyView === 0) {
                        rows.push(<span style={{ marginRight: 5 }}><Icon color='grey' name={match_info[key].icon} />{t("stats." + match_info[key].name)}</span>)
                    }
                } else if (match_info[key].type === "inputgooglephotourl") {
                    if (match_info[key].value) {
                        rows.push(<span style={{ marginRight: 5 }} className='cursorPointer' onClick={() => setGooglePhotosViewerStatus({ url: match_info[key].value, openView: true })}>
                            <Icon color='green' name={match_info[key].icon} />{t("stats." + match_info[key].name)}
                        </span>)
                    } else if (propsRoot.onlyView === 0) {
                        rows.push(<span style={{ marginRight: 5 }}><Icon color='grey' name={match_info[key].icon} />{t("stats." + match_info[key].name)}</span>)
                    }
                } else if (propsRoot.onlyView === 0) {
                    rows.push(<span style={{ marginRight: 5 }}>
                        <Icon color='grey' name={match_info[key].icon} />{t("stats." + match_info[key].name)}: {match_info[key].value ? match_info[key].value : <i>{t("stats.brak_informacji")}</i>}
                    </span>)
                } else if (propsRoot.onlyView === 1 && match_info[key].value) {
                    rows.push(<span style={{ marginRight: 5 }}>
                        <Icon color='grey' name={match_info[key].icon} />{t("stats." + match_info[key].name)}: {match_info[key].value ? match_info[key].value : <i>{t("stats.brak_informacji")}</i>}
                    </span>)
                }

            }
            return rows;
        } catch (e) {
            console.log(e)
        }
    }


    function OpenModalUser(row) {
        setfromUsersAdd({ subtournament_league_match_id: row.subtournament_league_match_id, match_member1_id: row.match_member1_id, match_member2_id: row.match_member2_id })
        setOpenUsersAdd(true)

    }
    const [formUsersAdd, setfromUsersAdd] = useState({ subtournament_league_match_id: 0, match_member1_id: -100, match_member2_id: -100 })
    const [openUsersAdd, setOpenUsersAdd] = useState(false)

    function exitModal() {
        setmessageStatus({ state: 0, text: "" })
        setfromUsersAdd({ subtournament_league_match_id: 0, match_member1_id: -100, match_member2_id: -100 })
        setopenModalScore(null)
        setOpenUsersAdd(false)
    }

    function delMembersFromLeagueMatch(row) {

        ConnectWS("/generator/delMembersFromLeagueMatch", authContext.token, { subtournament_league_match_id: row.subtournament_league_match_id }, function (response, logout) {

            if (logout === 1) {
                addToast('Zaraz nastapi wylogowanie: ' + response.error, { appearance: 'error' });
                setTimeout(() => {
                    setAuth(false);
                }, 4000);
            } else if (response && response.status && Number(response.status) === 200) {
                addToast(response.data.message, { appearance: 'success' });
                authContext.changeRefreshView(authContext.refreshView + 1)
            } else {
                if (logout === 101) {
                    addToast(response, { appearance: 'info' });
                } else if (logout === 102) {
                    addToast(response, { appearance: 'warning' });
                } else {
                    addToast(response, { appearance: 'error' });
                }
            }




        });
    }

    function modifiDataJsonUserACC() {

        if ((formUsersAdd.match_member1_id < -90 || formUsersAdd.match_member1_id === null) || (formUsersAdd.match_member2_id < -90 || formUsersAdd.match_member2_id === null)) {
            setmessageStatus({ state: 1, text: "Wybierz uczestnika" })
            return
        }

        if (formUsersAdd.match_member1_id === formUsersAdd.match_member2_id) {
            setmessageStatus({ state: 1, text: "Wybierz różnych uczestników" })
            return
        }

        //addMembersToLeagueMatch -> subtournament_league_match_id, match_member1_id, match_member2_id
        let match_member1_id = formUsersAdd.match_member1_id
        let match_member2_id = formUsersAdd.match_member2_id
        let subtournament_league_match_id = formUsersAdd.subtournament_league_match_id
        setloading(true)
        ConnectWS("/generator/addMembersToLeagueMatch", authContext.token, { subtournament_league_match_id: subtournament_league_match_id, match_member1_id: match_member1_id, match_member2_id: match_member2_id }, function (response, logout) {

            if (logout === 1) {
                addToast('Zaraz nastapi wylogowanie: ' + response.error, { appearance: 'error' });
                setTimeout(() => {
                    setAuth(false);
                }, 4000);
            } else if (response && response.status && Number(response.status) === 200) {
                //setDataOpen(dataJson)
                //let textt = openData.sides.home.team.name + " [" + form.homeScore + ":" + form.visitorScore + "] " + openData.sides.visitor.team.name
                exitModal()
                addToast(response.data.message, { appearance: 'success' });
                authContext.changeRefreshView(authContext.refreshView + 1)
            } else {
                setmessageStatus({ state: 1, text: response })
            }
            setloading(false)



        });
    }


    function changeIndexA(i, row) {
        setlistUsersParams({ round_nr: row.round_nr, league_revenge_round: row.league_revenge_round })
        setactiveIndex(i)
    }


    

    return (
        <>
            {propsRoot.remoteJson && propsRoot.remoteJson.length > 0 && propsRoot.struct_confirmed === 0 ? <>
                <Accordion fluid styled>

                    {propsRoot.remoteJson.map((row, i) => <div key={i}>
                        <AccordionTitle
                            active={activeIndex === i}
                            index={i}
                            onClick={() => changeIndexA(i, row)}
                        >
                            <Icon name='dropdown' />
                            KOLEJKA {row.round_nr} {row.league_revenge_round ? "- rewanżowa" : ""}
                        </AccordionTitle>
                        <AccordionContent active={activeIndex === i}>
                            <div style={{ overflowX: "auto", width: width < 700 ? "100%" : "100%" }} >
                                <Table size='small' celled striped compact unstackable >
                                    <Table.Header>
                                        <Table.Row>

                                            <Table.HeaderCell singleLine width={2} textAlign='center'>{t("StronaDedykowana.data")}</Table.HeaderCell>
                                            <Table.HeaderCell singleLine width={4} textAlign='center'>{t("WidokTurnieju.gospodarz")}</Table.HeaderCell>
                                            <Table.HeaderCell singleLine width={4} textAlign='center'>{t("WidokTurnieju.gosc")}</Table.HeaderCell>
                                            <Table.HeaderCell singleLine width={1} textAlign='center'></Table.HeaderCell>
                                        </Table.Row>
                                    </Table.Header>
                                    <Table.Body>

                                        {row.matches && row.matches.length > 0 ?
                                            row.matches.map((line3, j) =>
                                                line3.match_pause === 1 ?
                                                    <Table.Row key={j}>
                                                        <Table.Cell textAlign='center'>
                                                            {line3.match_date === null ? t("WidokTurnieju.do_ustalenia") : changeFormatDateTimeBS(line3.match_date)}
                                                        </Table.Cell>
                                                        <Table.Cell textAlign='center' colSpan={2}>
                                                            {t("WidokTurnieju.pauza")} - {line3.match_member1_id === 0 ? line3.match_member2_id !== null ? line3.member2_name : <Icon name="add" className='cursorPointer hiddenPrint' title="Przypisz uczestnika" onClick={() => OpenModalUser(line3)} /> : line3.match_member1_id !== null ? line3.member1_name : <Icon name="add" className='cursorPointer hiddenPrint' title="Przypisz uczestnika" onClick={() => OpenModalUser(line3)} />}
                                                        </Table.Cell>
                                                        <Table.Cell textAlign='center' colSpan={2}>
                                                            {line3.match_member1_id > 0 || line3.match_member2_id > 0 ? <Icon name="trash" className='cursorPointer hiddenPrint' title="Skasuj pary" onClick={() => delMembersFromLeagueMatch(line3)} /> : ""}

                                                        </Table.Cell>
                                                    </Table.Row>
                                                    :
                                                    <Table.Row key={j}>

                                                        <Table.Cell textAlign='center'>{line3.match_date === null ? t("WidokTurnieju.do_ustalenia") : line3.match_date}</Table.Cell>
                                                        <Table.Cell textAlign='center' singleLine>
                                                            {line3.match_member1_id === null ?
                                                                <Icon name="add" className='cursorPointer hiddenPrint' title="Przypisz uczestnika" onClick={() => OpenModalUser(line3)} /> :
                                                                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                                                                    <figure className='imgPlayer'><span style={{ marginRight: 5 }}>{getlinkplayerprofile(line3.member1_id, line3.member1_name, 1, 16, "_blank")}</span><ShowImgAvatar urlpic={line3.member1_img_file} mtype={"0"} msize={'mini'} /><figcaption></figcaption></figure>
                                                                </div>
                                                            }
                                                        </Table.Cell>

                                                        <Table.Cell textAlign='center' singleLine>
                                                            {line3.match_member2_id === null ?
                                                                <Icon name="add" className='cursorPointer hiddenPrint' title="Przypisz uczestnika" onClick={() => OpenModalUser(line3)} /> :

                                                                <figure className='imgPlayer'><ShowImgAvatar urlpic={line3.member2_img_file} mtype={"0"} msize={'mini'} /><figcaption>{getlinkplayerprofile(line3.member2_id, line3.member2_name, 1, 16, "_blank")}</figcaption></figure>
                                                            }</Table.Cell>
                                                        <Table.Cell textAlign='center' colSpan={3}>
                                                            {line3.match_member1_id > 0 || line3.match_member2_id > 0 ? <Icon name="trash" className='cursorPointer hiddenPrint' title="Skasuj pary" onClick={() => delMembersFromLeagueMatch(line3)} /> : ""} </Table.Cell>
                                                    </Table.Row>)
                                            : ""}

                                    </Table.Body>


                                </Table>
                            </div>


                        </AccordionContent>
                    </div>)}



                </Accordion>
            </> : propsRoot.remoteJson && propsRoot.remoteJson.tournament_json && propsRoot.remoteJson.tournament_json.length > 0 ? <>
                <div ref={contextRef}>
                    {propsRoot.onlyView === 1 ? "" : <Segment className='CardBlack hiddenPrint' style={{ marginTop: 5 }}>
                        <Label color='brown' ribbon>
                            Konfiguracja awansów i spadków
                        </Label>
                        <Form size='mini' onSubmit={setLeagueParams}>
                            <Form.Group widths='equal'>
                                <Form.Field>
                                    <label>Ilość uczestników awansujących</label>
                                    <Form.Field required control={Input} placeholder='Ilość uczestników awansujących' type='number' min="0" max="10" value={form.league_winners_count} onChange={(e, d) => setForm({ ...form, league_winners_count: d.value ? Number(d.value) : null })} />
                                </Form.Field>
                                <Form.Field>
                                    <label>Ilość uczestników spadających</label>
                                    <Form.Field required control={Input} re placeholder='Ilość uczestników spadających' type='number' min="0" max="10" requ value={form.league_losers_count} onChange={(e, d) => setForm({ ...form, league_losers_count: d.value ? Number(d.value) : null })} />
                                </Form.Field>
                                <Button color='brown' type='submit'>ZAPISZ</Button>
                            </Form.Group>
                        </Form>
                    </Segment>}

                    {propsRoot.viewTV === 1 ? "" : <>

                        <div style={{ margin: 10 }}>
                            <Form size='mini'>
                                <Form.Group widths='equal' >
                                    <Form.Field>
                                        <label>{t("WidokTurnieju.wybierz_kolejke")}:</label>
                                        <Dropdown
                                            style={{ backgroundColor: authContext.darkMode ? "#7d7d7d" : '#fafafa', marginBottom: 20 }}
                                            placeholder='Wybierz kolejkę do wyświetlenia'
                                            fluid
                                            selection
                                            value={currRound}
                                            options={roundOptions}
                                            onChange={(e, d) => funCurrRound(d.value)}
                                        />
                                    </Form.Field>
                                    <Form.Field>
                                        <label>{t("WidokTurnieju.wybierz_uczestnika")}:</label>
                                        <Dropdown
                                            style={{ backgroundColor: authContext.darkMode ? "#7d7d7d" : '#fafafa', marginBottom: 20 }}
                                            placeholder='Wybierz uczestnika do wyświetlenia'
                                            fluid
                                            selection
                                            value={currMember}
                                            options={membersOptions}
                                            onChange={(e, d) => funcurrMember(d.value)}
                                        />
                                    </Form.Field>
                                </Form.Group>
                            </Form>
                        </div>
                        <div className="panel-wrappertl">
                            <a href="#showtl" className="showtl btntl" id="showtl">{t("WidokTurnieju.pokaz_harmonogram")}</a>
                            <a href="#hidetl" className="hidetl btntl" id="hidetl">{t("WidokTurnieju.ukryj_harmonogram")}</a>
                            <div className="paneltl">
                                <Grid stackable style={{ margin: 0, padding: 0 }} >
                                    <Grid.Row stretched columns={width < 1300 ? 1 : currRound > 0 ? 1 : 2}  >

                                        {propsRoot.remoteJson.tournament_json.map((line3, j) =>
                                            currRound === line3.round_nr || currRound === 0 ?
                                                <Grid.Column style={{ marginBottom: 10 }}>

                                                    <Segment className={'CardBlack'} key={j}>
                                                        <Label color={line3.league_revenge_round > 0 ? 'brown' : 'blue'} ribbon>
                                                            {t("WidokTurnieju.kolejka")} {line3.round_nr} {line3.league_revenge_round > 0 ? <>({line3.league_revenge_round} -  {t("WidokTurnieju.rewanzowa")})</> : ""}
                                                        </Label>
                                                        {propsRoot.dedicated && width < 1400 ?
                                                            <List divided verticalAlign='middle'>

                                                                {line3.matches && line3.matches.length > 0 ?
                                                                    line3.matches.sort((a, b) => a.match_pause - b.match_pause).map((line3, j) =>
                                                                        currMember === 0 || (currMember > 0 && (line3.match_member1_id === currMember || line3.match_member2_id === currMember)) ?
                                                                            line3.match_pause === 1 ?
                                                                                <Message>
                                                                                    {line3.match_member1_id === 0 ?
                                                                                        <figure className='imgPlayer'><span style={{ marginRight: 10 }}>{t("WidokTurnieju.pauza")}</span> <ShowImgAvatar urlpic={line3.member2_img_file} mtype={"0"} msize={'mini'} /><figcaption>{getlinkplayerprofile(line3.member2_id, line3.member2_name, 1, 16, "_blank")}</figcaption></figure>
                                                                                        : <figure className='imgPlayer'><span style={{ marginRight: 10 }}>{t("WidokTurnieju.pauza")}</span> <ShowImgAvatar urlpic={line3.member1_img_file} mtype={"0"} msize={'mini'} /><figcaption>{getlinkplayerprofile(line3.member1_id, line3.member1_name, 1, 16, "_blank")}</figcaption></figure>}
                                                                                </Message>
                                                                                :
                                                                                <Table size='small' style={{ marginTop: 5, lineHeight: "12px" }} celled compact unstackable >
                                                                                    {line3.match_info && getmatch_info(line3.match_info, line3).length > 0 ? <Table.Row key={j + 100000}>

                                                                                        <Table.Cell colSpan={2} textAlign='left' style={{ fontSize: 12, lineHeight: "13px" }}>
                                                                                            {getmatch_info(line3.match_info, line3)}
                                                                                        </Table.Cell>

                                                                                    </Table.Row> : ""}
                                                                                    <Table.Row>
                                                                                        <Table.Cell textAlign='center' style={{ position: "relative" }}>
                                                                                            <figure className='imgPlayer' style={{ marginLeft: 5 }}><ShowImgAvatar urlpic={line3.member1_img_file} mtype={"0"} msize={'mini'} /><figcaption style={{ textAlign: "left" }}>{getlinkplayerprofile(line3.member1_id, line3.member1_name, 1, 15, "_blank")}</figcaption></figure>
                                                                                            <span style={{ fontSize: 8, position: "absolute", top: 0, right: 3 }}>{t("WidokTurnieju.gospodarz")}</span>
                                                                                        </Table.Cell>
                                                                                        <Table.Cell textAlign='center' singleLine width={1} style={{ fontSize: 15, backgroundColor: getColorWinner(line3.member1_score, line3.member2_score, 1) }}>
                                                                                            {line3.member1_score !== null ? <b> {line3.member1_score} </b> : "-"}
                                                                                        </Table.Cell>
                                                                                        {showScoreDTab(line3.match_details, line3.match_member1_id)}
                                                                                    </Table.Row>
                                                                                    <Table.Row>
                                                                                        <Table.Cell textAlign='center' style={{ position: "relative" }}>
                                                                                            <figure className='imgPlayer' style={{ marginLeft: 5 }}><ShowImgAvatar urlpic={line3.member2_img_file} mtype={"0"} msize={'mini'} /><figcaption style={{ textAlign: "left" }}>{getlinkplayerprofile(line3.member2_id, line3.member2_name, 1, 15, "_blank")}</figcaption></figure>
                                                                                            <span style={{ fontSize: 8, position: "absolute", top: 0, right: 3 }}>{t("WidokTurnieju.gosc")}</span>
                                                                                        </Table.Cell>
                                                                                        <Table.Cell textAlign='center' singleLine width={1} style={{ fontSize: 15, backgroundColor: getColorWinner(line3.member1_score, line3.member2_score, 2) }}>
                                                                                            {line3.member2_score !== null ? <b> {line3.member2_score} </b> : "-"}
                                                                                        </Table.Cell>
                                                                                        {showScoreDTab(line3.match_details, line3.match_member2_id)}
                                                                                    </Table.Row>
                                                                                </Table>

                                                                            : ""
                                                                    )
                                                                    : ""}



                                                            </List>

                                                            :
                                                            <div style={{ overflowX: "auto", width: width < 700 ? "100%" : "100%" }} >
                                                                {/*
                                                                <Table size='small' style={{ marginTop: 5, lineHeight: "12px" }} inverted={propsRoot.isDarkTheme} celled striped compact unstackable >
                                                                    <Table.Header>
                                                                        <Table.Row>
                                                                            <Table.HeaderCell singleLine width={6} textAlign='right'>{t("WidokTurnieju.gospodarz")}</Table.HeaderCell>
                                                                            <Table.HeaderCell singleLine width={3} textAlign='center'></Table.HeaderCell>
                                                                            <Table.HeaderCell singleLine width={6} textAlign='left'>{t("WidokTurnieju.gosc")}</Table.HeaderCell>
                                                                            {propsRoot.remoteJson.member_type === "TEAM" ? <Table.HeaderCell singleLine width={1} textAlign='left'></Table.HeaderCell> : ""}
                                                                        </Table.Row>
                                                                    </Table.Header>
                                                                    <Table.Body>
                                                                        {line3.matches && line3.matches.length > 0 ?
                                                                            line3.matches.sort((a, b) => a.match_pause - b.match_pause).map((line3, j) =>
                                                                                currMember === 0 || (currMember > 0 && (line3.match_member1_id === currMember || line3.match_member2_id === currMember)) ?
                                                                                    line3.match_pause === 1 ?

                                                                                        <Table.Row key={j}>
                                                                                            <Table.Cell textAlign='center' colSpan={3}>
                                                                                                {line3.match_member1_id === 0 ?
                                                                                                    <figure className='imgPlayer'><span style={{ marginRight: 10 }}>{t("WidokTurnieju.pauza")}</span> <ShowImgAvatar urlpic={line3.member2_img_file} mtype={"0"} msize={'mini'} /><figcaption>{getlinkplayerprofile(line3.member2_id, line3.member2_name, 1, 16, "_blank")}</figcaption></figure>
                                                                                                    : <figure className='imgPlayer'><span style={{ marginRight: 10 }}>{t("WidokTurnieju.pauza")}</span> <ShowImgAvatar urlpic={line3.member1_img_file} mtype={"0"} msize={'mini'} /><figcaption>{getlinkplayerprofile(line3.member1_id, line3.member1_name, 1, 16, "_blank")}</figcaption></figure>}
                                                                                            </Table.Cell>
                                                                                        </Table.Row>
                                                                                        :
                                                                                        <>
                                                                                            {line3.match_info && getmatch_info(line3.match_info, line3).length > 0 ? <Table.Row key={j + 100000}>

                                                                                                <Table.Cell colSpan={4} textAlign='left' style={{ fontSize: 12, lineHeight: "13px" }}>{getmatch_info(line3.match_info, line3)}

                                                                                                </Table.Cell>

                                                                                            </Table.Row> : ""}
                                                                                            <Table.Row key={j}>


                                                                                                <Table.Cell textAlign='center' >

                                                                                                    <div className='imgPlayer2'><ShowImgAvatar urlpic={line3.member1_img_file} mtype={"0"} msize={width < 500 ? '' : width < 800 ? 'massive' : 'massive'} /><br /><b>{getlinkplayerprofile(line3.member1_id, line3.member1_name, 1, 16, "_blank")}</b></div>

                                                                                                </Table.Cell>
                                                                                                <Table.Cell textAlign='center' singleLine>
                                                                                                    {propsRoot.dedicated ? "" : <div style={{ marginBottom: 10, fontSize: 12 }}>
                                                                                                        {propsRoot.onlyView === 1 ? "" : <Icon className='cursorPointer hiddenPrint' title="Dodaj date" name="add" color='orange' onClick={() => setopenModalDate({ ...line3 })} />}
                                                                                                        {line3.match_date === null ? <span style={{ fontSize: 10 }}> <Icon name="calendar alternate" color='grey' />{t("WidokTurnieju.do_ustalenia")}</span> : <> <Icon name="calendar alternate" color='grey' />{changeFormatDateTimeBS(line3.match_date)}</>}

                                                                                                    </div>}
                                                                                                    <div style={{ fontSize: 16 }}>
                                                                                                        {propsRoot.onlyView === 1 ? "" : <Icon className='cursorPointer hiddenPrint' title="Dodaj wynik" name="add" color='orange' onClick={() => setopenModalScore({ ...line3 })} />}
                                                                                                        {line3.member1_score !== null && line3.member2_score !== null ? <><b>{line3.member1_score}:{line3.member2_score}</b>
                                                                                                            {propsRoot.dedicated ? <><br /> {showScoreDetails2(line3.match_details, line3.match_member1_id, line3.match_member2_id)} </> : <ShowScoreDetails refreshView={authContext.refreshView} matche_info={{ member1_img_file: line3.member1_img_file, member2_img_file: line3.member2_img_file, member1_name: line3.member1_name, member2_name: line3.member2_name, member1_score: line3.member1_score, member2_score: line3.member2_score }} systemId={propsRoot.systemId} match_detailstmp={{ subtournament_match_id: line3.subtournament_league_match_id, match_details: line3.match_details, match_member1_id: line3.match_member1_id, match_member2_id: line3.match_member2_id, reverse: false, checkboxMD: checkboxMD }} />}
                                                                                                        </> : "-:-"}
                                                                                                    </div>
                                                                                                </Table.Cell>
                                                                                                <Table.Cell textAlign='center' ><div className='imgPlayer2'><ShowImgAvatar urlpic={line3.member2_img_file} mtype={"0"} msize={width < 500 ? '' : width < 800 ? 'massive' : 'massive'} /><br /><b>{getlinkplayerprofile(line3.member2_id, line3.member2_name, 1, 16, "_blank")}</b></div>
                                                                                                </Table.Cell>
                                                                                                {propsRoot.remoteJson.member_type === "TEAM" ? <Table.Cell singleLine width={1} textAlign='center'>

                                                                                                    <Icon className='cursorPointer hiddenPrint' title="Statystyki" name="balance scale" color={'blue'} onClick={() => setopenModalmatch_details_ex({ open: true, row: line3, onlyView: propsRoot.onlyView, st: propsRoot.remoteJson })} />
                                                                                                </Table.Cell> : ""}
                                                                                            </Table.Row>

                                                                                        </>
                                                                                    : "")
                                                                            : ""}

                                                                    </Table.Body>


                                                                </Table>
                                                                */}

                                                                <Table size='small' style={{ marginTop: 5, lineHeight: "12px" }} inverted={propsRoot.isDarkTheme} celled striped compact unstackable >

                                                                    <Table.Body>
                                                                        {line3.matches && line3.matches.length > 0 ?
                                                                            line3.matches.sort((a, b) => a.match_pause - b.match_pause).map((line3, j) =>
                                                                                currMember === 0 || (currMember > 0 && (line3.match_member1_id === currMember || line3.match_member2_id === currMember)) ?
                                                                                    line3.match_pause === 1 ?

                                                                                        <Table.Row key={j}>
                                                                                            <Table.Cell textAlign='center' colSpan={3}>
                                                                                                {line3.match_member1_id === 0 ?
                                                                                                    <figure className='imgPlayer'><span style={{ marginRight: 10 }}>{t("WidokTurnieju.pauza")}</span> <ShowImgAvatar urlpic={line3.member2_img_file} mtype={"0"} msize={''} /><figcaption>{getlinkplayerprofile(line3.member2_id, line3.member2_name, 1, 16, "_blank")}</figcaption></figure>
                                                                                                    : <figure className='imgPlayer'><span style={{ marginRight: 10 }}>{t("WidokTurnieju.pauza")}</span> <ShowImgAvatar urlpic={line3.member1_img_file} mtype={"0"} msize={''} /><figcaption>{getlinkplayerprofile(line3.member1_id, line3.member1_name, 1, 16, "_blank")}</figcaption></figure>}
                                                                                            </Table.Cell>
                                                                                        </Table.Row>
                                                                                        :
                                                                                        <>
                                                                                            <Table.Row key={j} >
                                                                                                <Grid unstackable style={{ margin:0,padding:0,marginTop:10,marginBottom:20}}>
                                                                                                {line3.match_info && getmatch_info(line3.match_info, line3).length > 0 ?
                                                                                                        <Grid.Row style={{margin:0,padding:0,marginTop:10,marginBottom:20}}>
                                                                                                            <Grid.Column width={16} textAlign='center' style={{fontSize: width < 600 ? 16 : 14}}>
                                                                                                                {getmatch_info(line3.match_info, line3)}
                                                                                                            </Grid.Column>
                                                                                                        </Grid.Row>
                                                                                                        : ""}
                                                                                                    <Grid.Row verticalAlign='middle' stretched style={{margin:0,padding:0}}>
                                                                                                        <Grid.Column width={5} textAlign='left' >
                                                                                                            <div style={{ marginLeft: 10, marginTop: 10 }} className='imgPlayer2'><ShowImgAvatar urlpic={line3.member1_img_file} mtype={"0"} msize={width < 500 ? 'massive' : width < 800 ? 'massive' : line3.member1_img_file || line3.member2_img_file ?  'large' : 'massive'} /></div>
                                                                                                        </Grid.Column>
                                                                                                        <Grid.Column width={6} textAlign='center'>
                                                                                                            {propsRoot.dedicated ? "" : <div style={{ marginBottom: 10, fontSize: 16 }}>
                                                                                                                {propsRoot.onlyView === 1 ? "" : <Icon className='cursorPointer hiddenPrint' title="Dodaj date" name="add" color='orange' onClick={() => setopenModalDate({ ...line3 })} />}
                                                                                                                {line3.match_date === null ? <span style={{ fontSize: 16 }}> <Icon name="calendar alternate" color='grey' />{t("WidokTurnieju.do_ustalenia")}</span> : <> <Icon name="calendar alternate" color='grey' />{changeFormatDateTimeBS(line3.match_date)}</>}

                                                                                                            </div>}
                                                                                                            <div style={{ fontSize: 30 }}>
                                                                                                                {propsRoot.onlyView === 1 ? "" : <Icon className='cursorPointer hiddenPrint' title="Dodaj wynik" name="add" color='orange' onClick={() => setopenModalScore({ ...line3 })} />}
                                                                                                                {line3.member1_score !== null && line3.member2_score !== null ? <><span className={ propsRoot.remoteJson.member_type === "TEAM" && line3.match_details_ex ? "tllink cursorPointer" : ""} onClick={() => propsRoot.remoteJson.member_type === "TEAM" && line3.match_details_ex ?  setopenModalmatch_details_ex({ open: true, row: line3, onlyView: propsRoot.onlyView, st: propsRoot.remoteJson }) :""} ><b>{line3.member1_score}:{line3.member2_score}</b>{line3.walkover ? <span style={{fontSize:14}}> (WO)</span> :""}</span>
                                                                                                                    {propsRoot.dedicated ? <><br /> {showScoreDetails2(line3.match_details, line3.match_member1_id, line3.match_member2_id)} </> : <ShowScoreDetails fontSize={16} refreshView={authContext.refreshView} matche_info={{ member1_img_file: line3.member1_img_file, member2_img_file: line3.member2_img_file, member1_name: line3.member1_name, member2_name: line3.member2_name, member1_score: line3.member1_score, member2_score: line3.member2_score }} systemId={propsRoot.systemId} match_detailstmp={{ subtournament_match_id: line3.subtournament_league_match_id, match_details: line3.match_details, match_member1_id: line3.match_member1_id, match_member2_id: line3.match_member2_id, reverse: false, checkboxMD: checkboxMD }} />}
                                                                                                                </> : "-:-"}

                                                                                                               
                                                                                                            </div>
                                                                                                        </Grid.Column>
                                                                                                        <Grid.Column width={5} textAlign='right'>
                                                                                                            <div style={{ marginRight: 10, marginTop: 10 }} className='imgPlayer2'><ShowImgAvatar urlpic={line3.member2_img_file} mtype={"0"} msize={width < 500 ? 'massive' : width < 800 ? 'massive' : line3.member1_img_file || line3.member2_img_file ?  'large' : 'massive'} /></div>
                                                                                                        </Grid.Column>
                                                                                                    </Grid.Row>
                                                                                                    <Grid.Row style={{margin:0,padding:0,marginTop:5}}>
                                                                                                        <Grid.Column width={16} textAlign='center'>
                                                                                                            <b>{getlinkplayerprofile(line3.member1_id, line3.member1_name, 1, 18, "_blank")} VS {getlinkplayerprofile(line3.member2_id, line3.member2_name, 1, 18, "_blank")}</b>
                                                                                                        </Grid.Column>
                                                                                                    </Grid.Row>
                                                                                                   
                                                                                                </Grid>
                                                                                            </Table.Row>

                                                                                        </>
                                                                                    : "")
                                                                            : ""}

                                                                    </Table.Body>


                                                                </Table>
                                                            </div>
                                                        }
                                                    </Segment>

                                                </Grid.Column> : "")}

                                    </Grid.Row>
                                </Grid>
                            </div>

                            <div className="fadetl"></div>
                        </div>
                    </>}




                    <div className={propsRoot.viewTV === 1 ? "" : "panel-wrappertl"}>
                        {propsRoot.viewTV === 1 ? "" : <>
                            <a href="#showtl2" className="showtl btntl" id="showtl2">{t("WidokTurnieju.pokaz_wiecej")}</a>
                            <a href="#hidetl2" className="hidetl btntl" id="hidetl2">{t("WidokTurnieju.ukryj_wiecej")}</a>
                        </>}
                        <div className="paneltl">
                            {propsRoot.remoteJson && propsRoot.remoteJson.final_table ?

                                <div style={{ marginTop: 20 }}>


                                    {propsRoot.remoteJson && propsRoot.remoteJson.final_table ?
                                        <>
                                            <div style={{ overflowX: "auto", width: "100%" }} >
                                                <Divider />
                                                {league_revenge_round ? <Label color='blue'><Icon name="table" /> {t("WidokTurnieju.runda_zasadnicza")}</Label> : ""}
                                                <Table className='stickyf2c' style={{ zoom: propsRoot.zoom && width < 800 ? propsRoot.zoom : 1, marginTop: 5, fontSize: 13, lineHeight: "11px", marginBottom: 2 }} size='small' inverted={propsRoot.isDarkTheme} celled striped compact unstackable >
                                                    <Table.Header >
                                                        <Table.Row>
                                                            <Table.HeaderCell width={1} textAlign='center'>LP</Table.HeaderCell>
                                                            <Table.HeaderCell style={{ fontSize: 12 }} width={3} textAlign='center' >{t("WidokTurnieju.tabela_uczestnik")}</Table.HeaderCell>
                                                            {propsRoot.remoteJson.final_table.map((line, i) =>


                                                                <Table.HeaderCell style={{ width: 80 }} key={i} textAlign='center'>
                                                                    {i + 1}
                                                                </Table.HeaderCell>

                                                            )
                                                            }
                                                        </Table.Row>
                                                    </Table.Header>
                                                    <Table.Body>
                                                        {
                                                            propsRoot.remoteJson.final_table.map((line, i) =>
                                                                <Table.Row key={i}>
                                                                    <Table.Cell textAlign='center'>{i + 1}</Table.Cell>
                                                                    <Table.Cell width={3} textAlign='left' singleLine style={{ fontSize: 12 }}>
                                                                        <figure className='imgPlayer'><ShowImgAvatar urlpic={line.img_file} mtype={"0"} msize={"mini"} /><figcaption>{getlinkplayerprofile(line.member_id, line.member_name, 1, width < mobileWSmall() ? 13 : 14, "_blank")}</figcaption></figure>
                                                                    </Table.Cell>
                                                                    {propsRoot.remoteJson.final_table.map((linej, j) =>
                                                                        <Table.Cell key={j} style={{ position: "relative" }} disabled={i === j ? true : false} textAlign='center' singleLine>
                                                                            {i === j ? <Icon name="x" size='large' /> : showTableH2hScore(line.member_id, linej.member_id, 0)}
                                                                        </Table.Cell>
                                                                    )
                                                                    }
                                                                </Table.Row>

                                                            )}
                                                    </Table.Body>
                                                </Table>
                                            </div>
                                            {league_revenge_round ?
                                                <div style={{ overflowX: "auto", width: "100%" }} >
                                                    <Divider />
                                                    <Label color='brown'><Icon name="table" /> {t("WidokTurnieju.runda_rewanzowa")}</Label>
                                                    <Table className='stickyf2c' style={{ zoom: propsRoot.zoom && width < 800 ? propsRoot.zoom : 1, marginTop: 5, fontSize: 13, lineHeight: "11px", marginBottom: 2 }} size='small' inverted={propsRoot.isDarkTheme} celled striped compact unstackable >
                                                        <Table.Header>
                                                            <Table.Row>
                                                                <Table.HeaderCell width={1} textAlign='center'>LP</Table.HeaderCell>
                                                                <Table.HeaderCell style={{ fontSize: 12 }} width={3} textAlign='center' >{t("WidokTurnieju.tabela_uczestnik")}</Table.HeaderCell>
                                                                {propsRoot.remoteJson.final_table.map((line, i) =>


                                                                    <Table.HeaderCell style={{ width: 80 }} key={i} textAlign='center'>
                                                                        {i + 1}
                                                                    </Table.HeaderCell>

                                                                )
                                                                }
                                                            </Table.Row>
                                                        </Table.Header>
                                                        <Table.Body>
                                                            {
                                                                propsRoot.remoteJson.final_table.map((line, i) =>
                                                                    <Table.Row key={i}>
                                                                        <Table.Cell textAlign='center'>{i + 1}</Table.Cell>
                                                                        <Table.Cell width={3} textAlign='left' singleLine style={{ fontSize: 12 }}>
                                                                            <figure className='imgPlayer'><ShowImgAvatar urlpic={line.img_file} mtype={"0"} msize={"mini"} /><figcaption>{getlinkplayerprofile(line.member_id, line.member_name, 1, width < mobileWSmall() ? 13 : 14, "_blank")}</figcaption></figure>
                                                                        </Table.Cell>
                                                                        {propsRoot.remoteJson.final_table.map((linej, j) =>
                                                                            <Table.Cell key={j} style={{ position: "relative" }} disabled={i === j ? true : false} textAlign='center' singleLine>
                                                                                {i === j ? <Icon name="x" size='large' /> : showTableH2hScore(line.member_id, linej.member_id, 1)}
                                                                            </Table.Cell>
                                                                        )
                                                                        }
                                                                    </Table.Row>

                                                                )}
                                                        </Table.Body>
                                                    </Table>
                                                </div>
                                                : ""}

                                        </>
                                        : ""}

                                </div>

                                : ""}
                        </div>

                        <div className="fadetl"></div>
                    </div>
                    {propsRoot.remoteJson.subtournament_finished ? "" :
                        <div style={{ marginTop: 30 }}>
                            <Label color={'blue'} >
                                <Icon name="table" /> {t("WidokTurnieju.tabela")}
                            </Label>

                            {propsRoot.onlyView === 1 ? '' : getInfoTab()}
                            <div style={{ overflowX: "auto", width: "100%", marginTop: 5 }} >

                                <Table className='stickyf2c' style={{ zoom: propsRoot.zoom && width < 800 ? propsRoot.zoom : 1 }} size='small' singleLine striped compact unstackable inverted={propsRoot.isDarkTheme}>
                                    <Table.Header>
                                        <Table.Row>
                                            <Table.HeaderCell singleLine width={1} textAlign='center' title="Miejsce">{width > 1000 ? t("WidokTurnieju.tabela_miejsce") : t("WidokTurnieju.tabela_miejsce_mala")}</Table.HeaderCell>
                                            <Table.HeaderCell textAlign='center' singleLine>{t("WidokTurnieju.tabela_uczestnik")}</Table.HeaderCell>
                                            <Table.HeaderCell width={1} textAlign='center' singleLine title="Punktacja">{t("WidokTurnieju.tabela_ptk")}</Table.HeaderCell>
                                            <Table.HeaderCell width={1} textAlign='center' singleLine title="Mecze">{t("WidokTurnieju.tabela_pojedynki")}</Table.HeaderCell>
                                            <Table.HeaderCell width={1} textAlign='center' singleLine title="Wygrana">{t("WidokTurnieju.tabela_wygrane")}</Table.HeaderCell>
                                            {showDraw ? <Table.HeaderCell width={1} textAlign='center' singleLine title="Remis">{t("WidokTurnieju.tabela_remis")}</Table.HeaderCell> : ""}
                                            <Table.HeaderCell width={1} textAlign='center' singleLine title="Przegrane">{t("WidokTurnieju.tabela_przegrane")}</Table.HeaderCell>
                                            <Table.HeaderCell width={1} textAlign='center' singleLine title="Bilans">{t("WidokTurnieju.tabela_bilans")}</Table.HeaderCell>
                                            <Table.HeaderCell width={1} textAlign='center' singleLine>{t("WidokTurnieju.tabela_stosunek")}</Table.HeaderCell>
                                            {isDetailsScore ? <>
                                                <Table.HeaderCell width={1} textAlign='center' singleLine style={{ lineHeight: "12px" }}>{t("WidokTurnieju.tabela_bilans")}
                                                    <br /><span style={{ fontSize: 9 }}>{t("WidokTurnieju.tabela_bilans_szczegoly")}</span></Table.HeaderCell>
                                                <Table.HeaderCell width={1} textAlign='center' singleLine style={{ lineHeight: "12px" }}>{t("WidokTurnieju.tabela_stosunek")}
                                                    <br /><span style={{ fontSize: 9 }}>{t("WidokTurnieju.tabela_bilans_szczegoly")}</span></Table.HeaderCell>
                                            </> : ""}
                                            <Table.HeaderCell width={2} textAlign='center' singleLine>{t("WidokTurnieju.tabela_forma")}</Table.HeaderCell>
                                        </Table.Row>
                                    </Table.Header>
                                    <Table.Body>
                                        {propsRoot.remoteJson && propsRoot.remoteJson.final_table && propsRoot.remoteJson.final_table.length > 0 ?
                                            propsRoot.remoteJson.final_table.map((line3, i) =>
                                                <Table.Row key={i} className={i < teamsAdvance ? 'winnerScoreP' : i >= propsRoot.remoteJson.final_table.length - teamsRelegated ? 'loserScoreP' : ''} >
                                                    <Table.Cell textAlign='center' singleLine>{line3.order_nr_range}</Table.Cell>
                                                    <Table.Cell textAlign='center' singleLine><figure className='imgPlayer'><ShowImgAvatar urlpic={line3.img_file} mtype={"0"} /><figcaption>{getlinkplayerprofile(line3.member_id, line3.member_name, 1, width < mobileWSmall() ? 13 : 16, "_blank")}</figcaption></figure></Table.Cell>
                                                    <Table.Cell textAlign='center'>{line3.subtournament_point} </Table.Cell>
                                                    <Table.Cell textAlign='center'>{line3.matches_played}</Table.Cell>
                                                    <Table.Cell textAlign='center'>{line3.wins}</Table.Cell>
                                                    {showDraw ? <Table.Cell textAlign='center'>{line3.draws}</Table.Cell> : ""}
                                                    <Table.Cell textAlign='center'>{line3.loses}</Table.Cell>
                                                    <Table.Cell textAlign='center' singleLine>{line3.total_points_winners} : {line3.total_points_losers}</Table.Cell>
                                                    <Table.Cell textAlign='center' singleLine>{getStosunekPTK(line3.total_points_winners, line3.total_points_losers)}</Table.Cell>
                                                    {isDetailsScore ? <>
                                                        <Table.Cell textAlign='center' singleLine>{line3.total_details_points_winners ? line3.total_details_points_winners : 0}:{line3.total_details_points_losers ? line3.total_details_points_losers : 0}</Table.Cell>
                                                        <Table.Cell textAlign='center' singleLine>{getStosunekPTK(line3.total_details_points_winners ? line3.total_details_points_winners : 0, line3.total_details_points_losers ? line3.total_details_points_losers : 0)}</Table.Cell>
                                                    </> : ""}
                                                    <Table.Cell textAlign='center'>
                                                        {line3.last_5_matches_stats && line3.last_5_matches_stats.length === 5 ?
                                                            <div className='formawrap'>
                                                                {getFormIcon(line3.last_5_matches_stats[0])}
                                                                {getFormIcon(line3.last_5_matches_stats[1])}
                                                                {getFormIcon(line3.last_5_matches_stats[2])}
                                                                {getFormIcon(line3.last_5_matches_stats[3])}
                                                                {getFormIcon(line3.last_5_matches_stats[4])}
                                                            </div>
                                                            : ""}
                                                    </Table.Cell>
                                                </Table.Row>)
                                            : ""}

                                    </Table.Body>
                                    {teamsAdvance > 0 || teamsRelegated > 0 ?
                                        <Table.Footer>
                                            <Table.Row>
                                                <TableHeaderCell colSpan='10' style={{ fontSize: 12, lineHeight: "14px" }}>
                                                    {teamsAdvance > 0 ? <div className='winnerScoreP' style={{ width: 60, textAlign: "center", margin: 2 }}>{t("WidokTurnieju.awans")}</div> : ""}
                                                    {teamsRelegated > 0 ? <div className='loserScoreP' style={{ width: 60, textAlign: "center", margin: 2 }}>{t("WidokTurnieju.spadek")}</div> : ""}
                                                </TableHeaderCell>
                                            </Table.Row>
                                        </Table.Footer>
                                        : ""}
                                </Table>



                            </div>
                        </div>

                    }


                    {membersStatsAll.data && membersStatsAll.data.length > 0 && propsRoot.viewTV !== 1 ?
                        <div style={{ marginTop: 30 }}>

                            <div className={propsRoot.viewTV === 1 ? "" : "panel-wrappertl"}>
                                {propsRoot.viewTV === 1 ? "" : <>
                                    <a href="#showtlM" className="showtl btntl" id="showtlM">{t("WidokTurnieju.pokaz_wiecej")}</a>
                                    <a href="#hidetlM" className="hidetl btntl" id="hidetlM">{t("WidokTurnieju.ukryj_wiecej")}</a>
                                </>}

                                <Input style={{ marginTop: 10 }} placeholder='Szukaj... min 3 znaki' onChange={(e, d) => searchTableDataStats(d.value)} />

                                <div className="paneltl">
                                    <div style={{ overflowX: "auto", width: "100%" }} >
                                        <Table className='stickyf2c' style={{ marginTop: 5, fontSize: 13, lineHeight: "11px", marginBottom: 2 }} size='small'
                                            inverted={propsRoot.isDarkTheme} celled striped compact unstackable sortable >

                                            <Table.Header >

                                                <Table.Row>


                                                    <Table.HeaderCell width={1} textAlign='center'></Table.HeaderCell>
                                                    <Table.HeaderCell textAlign='center'>{t("WidokTurnieju.zawodnik")}</Table.HeaderCell>
                                                    <Table.HeaderCell textAlign='center'>{t("WidokTurnieju.druzyna")}</Table.HeaderCell>
                                                    {
                                                        membersStatsAll.data[0].stats.map((line, i) =>
                                                            <Table.HeaderCell sorted={membersStatsAll.column === line.key ? membersStatsAll.direction : null}
                                                                onClick={() => clickSortTable(line.key, i)} textAlign='center'>{t("stats." + line.key).toLocaleUpperCase()}</Table.HeaderCell>
                                                        )}

                                                </Table.Row>
                                            </Table.Header>
                                            <Table.Body>
                                                {
                                                    membersStatsAll.filterData.map((line, i) =>
                                                        <Table.Row key={i}>
                                                            <Table.Cell textAlign='center'>{i + 1}</Table.Cell>
                                                            <Table.Cell textAlign='center' singleLine><figure className='imgPlayer'><ShowImgAvatar urlpic={line.member_img} mtype={"0"} /><figcaption>{getlinkplayerprofile(line.member_id, line.member_name, 1, width < mobileWSmall() ? 13 : 14, "_blank")}</figcaption></figure></Table.Cell>
                                                            <Table.Cell textAlign='center' singleLine><figure className='imgPlayer'><ShowImgAvatar urlpic={line.team_img} mtype={"0"} /><figcaption>{getlinkplayerprofile(line.team_id, line.team_name, 1, width < mobileWSmall() ? 13 : 14, "_blank")}</figcaption></figure></Table.Cell>

                                                            {line.stats.map((line1, i) =>
                                                                line1.key === 'matche_cards' ? <Table.Cell width={2} textAlign='center'>
                                                                    <div style={{ width: 120, margin: "0 auto" }}>
                                                                        <div style={{ display: "inline-block" }}><Image style={{ height: 20 }} spaced src={process.env.REACT_APP_PUBLIC_URL + "/img/icon/yellowc.png"} />{line1.value.yellow ? line1.value.yellow : 0}</div>
                                                                        <div style={{ display: "inline-block" }}><Image style={{ height: 20 }} spaced src={process.env.REACT_APP_PUBLIC_URL + "/img/icon/cardsyr.png"} />{line1.value.yr ? line1.value.yr : 0}</div>
                                                                        <div style={{ display: "inline-block" }}><Image style={{ height: 20 }} spaced src={process.env.REACT_APP_PUBLIC_URL + "/img/icon/redc.png"} />{line1.value.red ? line1.value.red : 0}</div>
                                                                    </div>
                                                                </Table.Cell> : <Table.Cell width={1} textAlign='center'>{line1.value}</Table.Cell>

                                                            )}



                                                        </Table.Row>

                                                    )}

                                            </Table.Body>
                                        </Table>
                                    </div>
                                </div>

                                <div className="fadetl"></div>
                            </div>
                        </div>
                        : ""}
                    {top10members && top10members.length > 0 && propsRoot.viewTV !== 1 ?
                        <div style={{ marginTop: 30 }}>

                            <div className={propsRoot.viewTV === 1 ? "" : "panel-wrappertl"}>
                                {propsRoot.viewTV === 1 ? "" : <>
                                    <a href="#showtlM" className="showtl btntl" id="showtlM">{t("WidokTurnieju.pokaz_wiecej")}</a>
                                    <a href="#hidetlM" className="hidetl btntl" id="hidetlM">{t("WidokTurnieju.ukryj_wiecej")}</a>
                                </>}
                                <div className="paneltl">
                                    <div style={{ overflowX: "auto", width: "100%" }} >
                                        <Table className='stickyf2c' style={{ marginTop: 5, fontSize: 13, lineHeight: "11px", marginBottom: 2 }} size='small'
                                            inverted={propsRoot.isDarkTheme} celled striped compact unstackable >

                                            <Table.Header >

                                                <Table.Row>
                                                    <Table.HeaderCell width={1} textAlign='center'></Table.HeaderCell>
                                                    <Table.HeaderCell textAlign='center'>{t("WidokTurnieju.zawodnik")}</Table.HeaderCell>
                                                    <Table.HeaderCell textAlign='center'>{t("WidokTurnieju.druzyna")}</Table.HeaderCell>
                                                    <Table.HeaderCell width={1} textAlign='center'>{width > 800 ? t("WidokTurnieju.tabela_pojedynki") : "M"}</Table.HeaderCell>
                                                    <Table.HeaderCell width={1} textAlign='center'>{width > 800 ? t("WidokTurnieju.tabela_wygrane") : "W"}</Table.HeaderCell>
                                                    <Table.HeaderCell width={1} textAlign='center'>{width > 800 ? t("WidokTurnieju.tabela_przegrane") : "P"}</Table.HeaderCell>
                                                    <Table.HeaderCell width={1} textAlign='center' style={{ lineHeight: "12px" }}>{width > 800 ? t("WidokTurnieju.tabela_wygrany_set") : "Ws"}</Table.HeaderCell>
                                                    <Table.HeaderCell width={1} textAlign='center' style={{ lineHeight: "12px" }}>{width > 800 ? t("WidokTurnieju.tabela_przegrany_set") : "Ps"}</Table.HeaderCell>
                                                    <Table.HeaderCell width={1} textAlign='center'>%</Table.HeaderCell>
                                                </Table.Row>
                                            </Table.Header>
                                            <Table.Body>
                                                {
                                                    top10members.map((line, i) =>
                                                        <Table.Row key={i}>
                                                            <Table.Cell textAlign='center'>{i + 1}</Table.Cell>
                                                            <Table.Cell textAlign='center' singleLine><figure className='imgPlayer'><ShowImgAvatar urlpic={line.member_img} mtype={"0"} /><figcaption>{getlinkplayerprofile(line.member_id, line.member_name, 1, width < mobileWSmall() ? 13 : 14, "_blank")}</figcaption></figure></Table.Cell>
                                                            <Table.Cell textAlign='center' singleLine><figure className='imgPlayer'><ShowImgAvatar urlpic={line.team_img} mtype={"0"} /><figcaption>{getlinkplayerprofile(line.team_id, line.team_name, 1, width < mobileWSmall() ? 13 : 14, "_blank")}</figcaption></figure></Table.Cell>
                                                            <Table.Cell textAlign='center'>{line.total_points_matches}</Table.Cell>
                                                            <Table.Cell textAlign='center'>{line.total_points_winners}</Table.Cell>
                                                            <Table.Cell textAlign='center'>{line.total_points_losers}</Table.Cell>
                                                            <Table.Cell textAlign='center'>{line.total_points_winners_small}</Table.Cell>
                                                            <Table.Cell textAlign='center'>{line.total_points_losers_small}</Table.Cell>
                                                            <Table.Cell textAlign='center'>{getSkut(line.total_points_winners, line.total_points_winners, line.total_points_losers, 0)}</Table.Cell>

                                                        </Table.Row>

                                                    )}
                                            </Table.Body>
                                        </Table>
                                    </div>
                                </div>

                                <div className="fadetl"></div>
                            </div>
                        </div>
                        : ""}

                </div>
            </> : <Message>Brak informacji</Message>}
            <Modal

                open={openModalScore !== null ? true : false}
                centered={width < 900 ? false : true}



            >
                <Modal.Header >Wprowadź wynik</Modal.Header>
                <Modal.Content style={{ backgroundColor: "#f5f5f5" }}>
                    <Modal.Description >
                        {(authContext && authContext.user && authContext.user.subscription_info && authContext.user.subscription_info.subscription_active === true) || isDemo ?
                            <Container textAlign='center'>

                                <Form size='small' onSubmit={saveScorePre} >
                                    <Form.Field inline>
                                        <Grid verticalAlign='middle' fluid stackable columns={3}>
                                            <Grid.Row>
                                                <Grid.Column width={7} textAlign='center'><b><label style={{ fontSize: 16, color: "#646bfa" }}>{openModalScore ? openModalScore.member1_name : ""}</label></b><br />
                                                    <Input disabled={blockEditScore(openModalScore ? openModalScore.member1_score : 0, openModalScore ? openModalScore.member2_score : 0, propsRoot.systemId, openModalScore ? openModalScore.match_details : null)} type="number" autoFocus fluid value={openModalScore ? openModalScore.member1_score : 0} onChange={(e, d) => setopenModalScore({ ...openModalScore, member1_score: d.value, match_details: propsRoot.systemId === 5 ? null : openModalScore.match_details })} style={{ padding: 5, margin: 5, border: "2px solid #c9ccff", borderRadius: 5 }} />
                                                </Grid.Column>
                                                <Grid.Column textAlign='center' width={2}>
                                                    <label style={{ fontSize: 25, fontWeight: "bold", color: "#737373" }}>VS</label>
                                                </Grid.Column>
                                                <Grid.Column width={7} textAlign='center'>
                                                    <b><label style={{ fontSize: 16, color: "#fa6464" }} >{openModalScore ? openModalScore.member2_name : ""}</label></b><br />
                                                    <Input disabled={blockEditScore(openModalScore ? openModalScore.member1_score : 0, openModalScore ? openModalScore.member2_score : 0, propsRoot.systemId, openModalScore ? openModalScore.match_details : null)} type="number" fluid value={openModalScore ? openModalScore.member2_score : 0} onChange={(e, d) => setopenModalScore({ ...openModalScore, member2_score: d.value, match_details: propsRoot.systemId === 5 ? null : openModalScore.match_details })} style={{ padding: 5, margin: 5, border: "2px solid #ffc9c9", borderRadius: 5 }} />
                                                </Grid.Column>
                                            </Grid.Row>
                                        </Grid>
                                        <Checkbox disabled={openModalScore && (openModalScore.member1_score>0 || openModalScore.member2_score>0) ? false : true} toggle label="Wpisz wynik i oznacz mecz jako walkower" checked={openModalScore ? openModalScore.walkover ? true : false : false} onChange={(e,d)=>setopenModalScore({ ...openModalScore, walkover: d.checked  })} />
                                        {openModalScore && openModalScore.walkover ? 
                                        <Message info>
                                       
                                            Ten pojedynek został oznaczony jako walkower, wygrywa <b>{openModalScore.member2_score > openModalScore.member1_score ? openModalScore.member2_name : openModalScore.member1_name  } {openModalScore.member2_score > openModalScore.member1_score ? <>{openModalScore.member2_score}:{openModalScore.member1_score}</> : <>{openModalScore.member1_score}:{openModalScore.member2_score}</>  } </b>
                                        </Message>
                                        :""}
                                    </Form.Field>
                                    {openModalScore ?
                                        <GetScoreDetails tournament_free={propsRoot.tournament_free} typeSubTournament={propsRoot.remoteJson.type} systemId={propsRoot.systemId} openModalScoreTMP={{ subtournament_match_id: openModalScore.subtournament_league_match_id, member1_name: openModalScore.member1_name, member2_name: openModalScore.member2_name, member1_score: openModalScore.member1_score, member2_score: openModalScore.member2_score, match_details: openModalScore.match_details, match_member1_id: openModalScore.match_member1_id, match_member2_id: openModalScore.match_member2_id }} openModalScore={openModalScore} setopenModalScore={setopenModalScore} />
                                        : ""}
                                    <Button size="tiny"
                                        content="ZATWIERDŹ"
                                        labelPosition='right'
                                        icon='checkmark'
                                        fluid
                                        color='google plus'
                                        loading={loading}
                                        disabled={loading}
                                    />
                                </Form>

                            </Container>
                            : <Message info>
                                <Message.Header>Informacja:</Message.Header>
                                <p>Brak aktywnej subskrypcji w dowolnym pakiecie diamentowym.</p>
                            </Message>}
                    </Modal.Description>
                    {messageStatus.state === 1 ?
                        <Message negative>
                            <Message.Header>Bład:</Message.Header>
                            <p>{messageStatus.text}</p>
                        </Message> : ''}
                    {messageStatus.state === 2 ?
                        <Message positive>

                            <p>{messageStatus.text}</p>
                        </Message> : ''}
                </Modal.Content>
                <Modal.Actions >
                    <Button floated='left' size="tiny" color='grey' labelPosition='right' icon='trash' onClick={() => delScore()} content="KASUJ WYNIK" />
                    <Button size="tiny" color='black' onClick={() => setopenModalScore(null)}>
                        WYJDŹ
                    </Button>

                </Modal.Actions>
            </Modal>

            <Modal
                onClose={() => setopenModalDate(null)}
                open={openModalDate !== null ? true : false}
                centered={width < 900 ? false : true}
                closeIcon


            >
                <Modal.Header >Wprowadź date</Modal.Header>
                <Modal.Content style={{ backgroundColor: "#f5f5f5" }}>
                    <Modal.Description >
                        <Container textAlign='center'>

                            <Form size='small' onSubmit={saveDatePre} >
                                <Form.Group>
                                    <Form.Field  >
                                        <InputCalendarAndTime form={openModalDate} setForm={setopenModalDate} inputValue={"match_date"} />

                                    </Form.Field>
                                    <Form.Field>
                                        <Button style={{ marginLeft: 10 }} type='button' onClick={() => delDate()}>SKASUJ DATĘ</Button>
                                    </Form.Field>
                                </Form.Group>
                                <Button size="tiny"
                                    content="ZATWIERDŹ"
                                    labelPosition='right'
                                    icon='checkmark'
                                    fluid
                                    color='google plus'
                                    loading={loading}
                                    disabled={loading}
                                />
                            </Form>

                        </Container>
                    </Modal.Description>
                    {messageStatus.state === 1 ?
                        <Message negative>
                            <Message.Header>Bład:</Message.Header>
                            <p>{messageStatus.text}</p>
                        </Message> : ''}
                    {messageStatus.state === 2 ?
                        <Message positive>

                            <p>{messageStatus.text}</p>
                        </Message> : ''}
                </Modal.Content>
                <Modal.Actions >
                    <Button size="tiny" color='black' onClick={() => setopenModalDate(null)}>
                        WYJDŹ
                    </Button>

                </Modal.Actions>
            </Modal>
            <Modal
                onClose={() => exitModal()}
                onOpen={() => setOpenUsersAdd(true)}
                open={openUsersAdd}
                centered={width < 900 ? false : true}
                closeIcon
                dimmer='blurring'

            >
                <Modal.Header>Przypisz uczestnika</Modal.Header>
                <Modal.Content>
                    <Modal.Description>
                        <Container textAlign='left'>

                            <Form size='small' >

                                {formUsersAdd.match_member1_id === 0 && formUsersAdd.match_member2_id !== 0 ?
                                    <Form.Field
                                        control={Select} selectOnBlur={false}
                                        options={userList}
                                        value={formUsersAdd.match_member2_id}
                                        label="Wybierz uczestnika pauzyjacego"
                                        onChange={(event, data) => {
                                            setfromUsersAdd({ ...formUsersAdd, match_member2_id: data.value })
                                        }}
                                        search
                                    /> : ""}
                                {formUsersAdd.match_member1_id !== 0 && formUsersAdd.match_member2_id === 0 ?
                                    <Form.Field
                                        control={Select} selectOnBlur={false}
                                        options={userList}
                                        value={formUsersAdd.match_member1_id}
                                        label="Wybierz uczestnika pauzyjacego"
                                        onChange={(event, data) => {
                                            setfromUsersAdd({ ...formUsersAdd, match_member1_id: data.value })
                                        }}
                                        search
                                    /> : ""}
                                {formUsersAdd.match_member1_id !== 0 && formUsersAdd.match_member2_id !== 0 ?
                                    <>
                                        <Form.Field
                                            control={Select} selectOnBlur={false}
                                            options={userList}
                                            value={formUsersAdd.match_member1_id}
                                            label="Wybierz uczestnika - gospodarz"
                                            onChange={(event, data) => {
                                                setfromUsersAdd({ ...formUsersAdd, match_member1_id: data.value })
                                            }}
                                            search
                                        />   <Form.Field
                                            control={Select} selectOnBlur={false}
                                            options={userList}
                                            value={formUsersAdd.match_member2_id}
                                            label="Wybierz uczestnika - gości"
                                            onChange={(event, data) => {
                                                setfromUsersAdd({ ...formUsersAdd, match_member2_id: data.value })
                                            }}
                                            search
                                        /> </> : ""}


                            </Form>

                        </Container>
                    </Modal.Description>
                    {messageStatus.state === 1 ?
                        <Message negative>
                            <Message.Header>Bład:</Message.Header>
                            <p>{messageStatus.text}</p>
                        </Message> : ''}
                    {messageStatus.state === 2 ?
                        <Message positive>

                            <p>{messageStatus.text}</p>
                        </Message> : ''}
                </Modal.Content>
                <Modal.Actions>

                    <Button size="tiny" color='black' onClick={() => exitModal()}>
                        WYJDŹ
                    </Button>
                    <Button size="tiny"
                        content="ZATWIERDŹ"
                        labelPosition='right'
                        icon='checkmark'
                        onClick={() => modifiDataJsonUserACC()}
                        color='google plus'
                        loading={loading}
                        disabled={loading || formUsersAdd.member_id === null}
                    />
                </Modal.Actions>
            </Modal>
            <EditLeagueMatchInfo openModalmatch_info={openModalmatch_info} setopenModalmatch_info={setopenModalmatch_info} />
            <EditLeagueMatchDetailsEx openModalmatch_details_ex={openModalmatch_details_ex} setopenModalmatch_details_ex={setopenModalmatch_details_ex} />
            <GooglePhotosViewer url={GooglePhotosViewerStatus.url} openView={GooglePhotosViewerStatus.openView} setGooglePhotosViewerStatus={setGooglePhotosViewerStatus} />
        </>
    )
}

export default BracketLeague;